import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj6 = () => {
  return (
    <Layout>
      <SEO
        title="Facebook Sentiment Analysis"
        description="Awesome python project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-6">
          <div className="py-1-heading">
            <h1>Facebook Sentiment Analysis</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Facebook%20Sentiment%20Analysis"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              First download facebook data and store it in excel file.
              <br />
              <br />
              We are using:-
              <br />
              => pandas library:-it provides easy to use data structures for
              data analysis.
              <br />
              =>NLTK library:-this is used to process human language.
              <br />
              =>nltk also provides sentiment analysis of human data.
              <br />
              <br />
              Sentiment analysis involves working out whether a piece of text is
              positive, negative or neutral.
              <br />
              <br />
              We will use VADER (Valence Aware Dictionary And Sentiment
              reasoner),
              <br />
              it also takes into account the intensity of the sentiment.
              <br />
              So VADER lexicon acts as a dictionary here.
              <br />
              <br />
              It's Just Mindblowing ...
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Nltk</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Sentiment</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Pandas</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>VADER</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj6
